<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" title="relacionar" :menu="menu"/>

    <div class="container-fluid qsuite-c-fluid-0">
        <div class="col-12">
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg-8">
                            <div class="form-group d-flex justify-content-end">
                                <input type="search" class="form-control campo-procurar" placeholder="Procurar" v-model="search">
                                <button type="submit" class="btn btn-info" @click="fetchTipologies">Buscar</button>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
                            <div class="form-group d-flex">
                                <!-- <router-link :to="{name: 'EnterpriseConfigTipologyNew'}">
                                    <button type="submit" class="btn btn-dark"><i class="far fa-file-alt mr-2"></i>Nova tipologia</button>
                                </router-link> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card-list-legenda"> 
                        <div class="card-body-list-legend d-none d-lg-block"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-12 col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status">
                                            <p class="text-truncate title-legend-list"><i class="fa fa-circle color-ico-legenda font-12" data-toggle="tooltip" data-placement="top" title="Status"></i></p>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <p class="text-truncate title-legend-list" style="width:45px">&nbsp;</p>
                                        </div>
                                        <div class="col-7 col-md-6 col-lg-9 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Imóvel</p>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <p class="text-truncate title-legend-list">Tipologia</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 align-self-center pad-content-list">
                                                        <p class="text-truncate title-legend-list">Unidade</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">&nbsp;</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-list" v-for="item in tipologies" :key="item.id"> 
                        <div class="card-body-list"> 
                            <div class="col-12 p-0 align-self-center">
                                <div class="col-12">
                                    <div class="row">
                                        <div class="col-12 col-md-auto col-lg-auto align-self-center order-1 order-md-1 order-lg-1 pad-content-list-status d-none d-lg-block">
                                            <i class="fa fa-circle text-success font-12" data-toggle="tooltip" data-placement="top" title="Status"></i>
                                        </div>
                                        <div class="col-3 col-md-auto col-lg-auto align-self-center text-left order-2 order-md-2 order-lg-2 pad-content-list-img">
                                            <!-- <img src="../../assets/images/users/widget-table-pic1.jpg" alt="user" class="rounded-circle card-body-list-img" /> -->
                                        </div>
                                        <div class="col-7 col-md-7 col-lg-9 p-0 order-3 align-self-center">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <h6 class="text-truncate card-body-list-title d-none d-lg-block">{{ getEnterprises(item) }}</h6>
                                                    </div>
                                                    <div class="col-12 col-md-12 col-lg-4 align-self-center pad-content-list">
                                                        <h6 class="text-truncate card-body-list-title">{{ item.name }}</h6>
                                                        <h6 class="text-truncate cpf d-none d-lg-block">{{ item.description }}</h6>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 align-self-center text-muted pad-content-list">
                                                        <h6 class="text-truncate cod-tec">Unidades relecionadas</h6>
                                                        <h6 class="empreendi">{{ getUnities(item) }}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-2 col-md-2 col-lg-auto align-self-center text-right ml-auto pr-0 order-4 pad-content-list-cta">
                                            <a class="btn btn-success rounded-circle btn-circle font-20 card-body-list-cta"  data-bs-toggle="modal" data-bs-target="#right-modal" href="#right-modal" @click="tipology_id = item.id">+</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="col-12 text-center p-4" v-if="tipologies.length == 0">
                    Nenhuma tipologia encontrada.
                </div>
            </div>
            <div class="col-12">
                <BasePagination
                    v-if="tipologies.length > 0"
                    :totalPages="pagination.totalPages"
                    :activePage="pagination.currentPage"
                    @to-page="toPage"
                    @per-page="perPage" />
            </div>
        </div>
    </div>

    <Tipology :tipology_id="tipology_id"/>
</div>
</template>
<script>
import TipologyService from "@/services/resources/TipologyService";
import Tipology from "@/components/Enterprise/Tipology.vue"
const service = TipologyService.build();

export default {
  components:{
       Tipology
  },
  data() {
    return {
      name: null,
      cond: [],
      search: '',
      tipologies: [],
      tipology_id: null,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 10,
        itens: 0
      },
      pages:[
        {
            name: 'dashboard',
            to: 'Dashboard'
        },
        {
            name: 'imóveis',
            to: 'Enterprise'
        }
      ],
      menu:[
        {
            name: 'Imóveis',
            to: 'Enterprise'
        }
      ]
    };
  },
  methods: {
    toPage(page) {
        this.pagination.currentPage = page;
        this.fetchTipologies();
    },
    perPage(qtd) {
        this.pagination.currentPage = 1;
        this.pagination.perPage = qtd;

        this.fetchTipologies();
    },
    getEnterprises(item){
        let enterprises = [];

        item.enterprises.forEach(element => {
            if(enterprises.indexOf(element.enterprise.name) == -1){
                enterprises.push(element.enterprise.name);
            }    
        });

        return enterprises.join(', ');
    },
    getUnities(item){
        let unities = [];

        item.enterprises.forEach(element => {
            if(unities.indexOf(element.number) == -1){
                unities.push(element.number);
            }    
        });

        return unities.join(', ');
    },
    fetchTipologies(){
        
        let data = {
            page: this.pagination.currentPage,
            per_page: this.pagination.perPage
        };

        if(this.search){
            data['search'] = this.search;
        }

        service
          .search(data)
            .then(resp => {
                this.tipologies = resp.data;
                this.pagination.totalPages = resp.last_page;
                this.pagination.itens = resp.total;
            })
            .catch(err => {
              console.log(err)
            })
    }

  },
  mounted() {
      this.fetchTipologies();
  },
};
</script>
<style scoped lang="scss">
.bg{
  background: #fff;
  padding: 10px;
  border: 1px #ddd solid;
  border-radius: 5px;
}
</style>