<template>
<div id="right-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg modal-right">
        <div class="modal-content">
            <div class="modal-header border-0">
                <button type="button" class="close" data-bs-dismiss="modal" @click="enterprise_id = null"><i class="ti-close font-20"></i></button>
            </div>
            <div class="modal-body">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 pt-4">
                            <!-- <img src="../../assets/images/hub_/hub-logo.svg" class="img-fluid" title="Logo" style="max-height:40px"> -->
                        </div>
                        <div class="col-12 col-md-4 col-lg-4 pt-4">
                            <p class="page-txt-view-chamado">TIPOLOGIA</p>
                            <h3 class="page-title-view-chamado text-dark font-weight-medium mb-1">{{ tipology_id }}</h3>
                        </div>
                        <div class="col-12 pt-4">
                            <p class="page-txt-view-chamado">UNIDADES</p>
                            <!-- <h3 class="page-title-view-chamado-2 font-weight-medium mb-1" style="color: #ff9300;">405, 601, 808</h3> -->
                        </div>
                        <div class="col-12 p-0 align-self-center px-0 py-3" style="background-color:#fff; border-radius:15px;">
                                <BaseEnterpriseBox v-model="enterprise_id" :changed="changed"/>
                        </div>
                            <div class="col-12 pt-3"></div>
                            <div class="col-12 pb-2">
                                <BaseAptBox v-model="unities" :enterprise_id="enterprise_id" :tipology_id="tipology_id" @unities="unitiesLoaded" :disabled_tipology="true" />
                            </div>
                            <div class="col-12 pt-3"></div>
                            <div class="col-12 pb-2">
                                <BaseTipologyBox v-model="tipology_id" :tipology_id="tipology_id"/>
                            </div>
                        <div class="col-12 pt-2">
                            <div class="form-actions">
                                <div class="text-right">
                                    <button type="reset" class="btn btn-dark" data-bs-dismiss="modal" @click="enterprise_id = null">Fechar</button>
                                    <button type="submit" class="btn btn-info ml-2" @click="save">Salvar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import EnterpriseService from "@/services/resources/EnterpriseService";
const service = EnterpriseService.build();


export default {
    props:{
        tipology_id:{
            type: Number
        }
    },
    data(){
        return{
            enterprise_id: null,
            unities: [],
            changed: false,
            unitiesSelected: []
        }
    },
    watch:{
        unities(){
            this.changed = true;
        },
        enterprise_id(){
            this.unities = [];
        }
    },
    methods:{
        unitiesLoaded(unities){
            this.unitiesSelected = unities;
        },
        find(unities, id){
            for(let i = 0; i < unities.length; i++)
                if(unities[i].unity_id == id)
                    return true;

            return false;
        },
        save(){

            let data = {
                tipologies: []
            };

            for(let i = 0; i < this.unities.length; i++){
                if(!this.find(data.tipologies, this.unities[i])){
                    data.tipologies.push({
                        unity_id: this.unities[i],
                        tipology_id: this.tipology_id
                    });
                }
            }

            if(this.unitiesSelected.length > 0){
                for(let i = 0; i < this.unitiesSelected.length; i++){
                    if(this.unities.indexOf(this.unitiesSelected[i]) == -1){
                        data.tipologies.push({
                            unity_id: this.unitiesSelected[i],
                            tipology_id: null
                        });
                    }
                }
            }
            
            data.id = this.enterprise_id

            service
                .update(data)
                .then(resp =>{
                     this.$bvToast.toast('Tipologia adicionada com sucesso!', {
                        title: 'Relacionar tipologia',
                        autoHideDelay: 5000,
                        type: 'success'
                    })
                })
                .catch(err => {
                     this.$bvToast.toast('Houve um erro inesperado, tente novamente mais tarde.', {
                        title: 'Relacionar tipologia',
                        autoHideDelay: 5000,
                        type: 'danger'
                    })
                });



        }
    }
}
</script>